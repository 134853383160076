import { createSlice } from '@reduxjs/toolkit';
import { GoogleBusiness, IAppleStore, Loading } from '../../../shared/types';
import { RootState } from '../../../store';
import { TransformedProduct } from '../../product/types';
import {
  APPLE_STORES_PER_PAGE,
  GOOGLE_BUSINESSESS_PER_PAGE,
} from '../../review/utils/constants';
import { YELP_LOCATIONS_PER_PAGE } from '../utils/constants';
import {
  addAirbnbAccount,
  addAmazonProductPage,
  addAppleAccount,
  addCapterraAccount,
  addGoogleAccount,
  addProducthuntAccount,
  addTripadvisorAccount,
  addTrustpilotAccount,
  addYelpAccount,
  deleteIntegration,
  getAppleStores,
  getGoogleBusinesses,
  getIntegrations,
  searchYelpBusiness,
  syncAirbnbAccount,
  syncAmazonProductReviews,
  syncAppleAccount,
  syncBookingReviews,
  syncCapterraAccount,
  syncGoogleAccount,
  syncProducthuntAccount,
  syncTripadvisorAccount,
  syncTrustpilotAccount,
  syncYelpAccount,
} from './actions';

export type IntegrationKey =
  | 'google'
  | 'yelp'
  | 'apple'
  | 'producthunt'
  | 'trustpilot'
  | 'capterra'
  | 'tripadvisor'
  | 'airbnb'
  | 'etsy';

export enum ReviewHeaders {
  REVIEW = 'REVIEW',
  RATING = 'RATING',
  REVIEW_DATE = 'REVIEW_DATE',
  HELPFUL_COUNT = 'HELPFUL_COUNT',
  STATUS = 'STATUS',
  REVIEWER_NAME = 'REVIEWER_NAME',
  REVIEWER_AVATAR = 'REVIEWER_AVATAR',
}

export enum IntegrationPlatform {
  YELP_REVIEWS = 'yelp_reviews',
  GOOGLE_MAPS_REVIEWS = 'google_maps_reviews',
  APPLE_REVIEWS = 'apple_reviews',
  PRODUCTHUNT_REVIEWS = 'producthunt_reviews',
  TRUSTPILOT_REVIEWS = 'trustpilot_reviews',
  CAPTERRA_REVIEWS = 'capterra_reviews',
  TRIPADVISOR_REVIEWS = 'tripadvisor_reviews',
  AIRBNB_REVIEWS = 'airbnb_reviews',
  AMAZON_REVIEWS = 'amazon_reviews',
}

export type Integration = {
  _id: string;
  siteId: string;
  placeId: string;
  pageId: string;
  dataId: string;
  domain: string;
  language: string;
  country: string;
  engine: IntegrationPlatform;
  start: number;
  pageTitle: string;
  nextPageToken: string;
  productId: string;
  propertyId: string;
  platformProduct: Omit<TransformedProduct, '_id'> | null;
};

export type Reviewer = {
  _id: string;
  instanceId: string;
  email: string;
  fullName: string;
  avatar: string | null;
  created_at: string;
  updated_at: string;
};

export type YelpBusiness = {
  placeId: string;
  website: string;
  phone: string;
  address: string;
  name: string;
  reviews: string;
  rating: string;
  image: string | null;
};

type IntegrationState = {
  searchingYelpBusiness: Loading;
  yelpBusinesses: YelpBusiness[];
  hasMoreYelpLocations: boolean;
  addingYelpAccount: Loading;
  syncingYelp: Loading;
  syncingGoogle: Loading;
  syncingApple: Loading;
  syncingProducthunt: Loading;
  syncingTrustpilot: Loading;
  syncingCapterra: Loading;
  loadingGoogleBusinesses: Loading;
  loadingAppleStores: Loading;
  googleBusinesses: GoogleBusiness[];
  appleStores: IAppleStore[];
  addingGoogleAccount: Loading;
  addingAppleAccount: Loading;
  hasMoreGoogleBusinessess: boolean;
  hasMoreAppleStores: boolean;
  getIntegrations: {
    loading: Loading;
    integrations: Record<IntegrationPlatform, Integration[]> | null;
  };
  deleteIntegration: {
    loading: Loading;
  };
  googleReviewsModalOpen: boolean;
  yelpReviewsModalOpen: boolean;
  appleReviewsModalOpen: boolean;
  producthuntReviewsModalOpen: boolean;
  trustpilotReviewsModalOpen: boolean;
  capterraReviewsModalOpen: boolean;
  tripadvisorReviewsModalOpen: boolean;
  airbnbReviewsModalOpen: boolean;
  etsyReviewsModalOpen: boolean;
  aliExpressReviewsModalOpen: boolean;
  addingProducthuntAccount: Loading;
  addingTrustpilotAccount: Loading;
  addingCapterraAccount: Loading;
  addingTripadvisorAccount: Loading;
  syncingTripAdvisor: Loading;
  addingAirbnbAccount: Loading;
  syncingAirbnb: Loading;
  amazonReviewModalOpen: boolean;
  addingAmazonProduct: Loading;
  syncingAmazonProduct: Loading;
  bookingReviewModalOpen: boolean;
  syncingBooking: Loading;
};

const initialState: IntegrationState = {
  searchingYelpBusiness: Loading.INITIAL,
  yelpBusinesses: [],
  hasMoreYelpLocations: false,
  addingYelpAccount: Loading.INITIAL,
  syncingYelp: Loading.INITIAL,
  syncingGoogle: Loading.INITIAL,
  syncingApple: Loading.INITIAL,
  syncingProducthunt: Loading.INITIAL,
  syncingTrustpilot: Loading.INITIAL,
  syncingCapterra: Loading.INITIAL,
  loadingGoogleBusinesses: Loading.INITIAL,
  loadingAppleStores: Loading.INITIAL,
  googleBusinesses: [],
  appleStores: [],
  addingGoogleAccount: Loading.INITIAL,
  addingAppleAccount: Loading.INITIAL,
  hasMoreGoogleBusinessess: false,
  hasMoreAppleStores: false,
  getIntegrations: {
    loading: Loading.INITIAL,
    integrations: null,
  },
  deleteIntegration: {
    loading: Loading.INITIAL,
  },
  googleReviewsModalOpen: false,
  yelpReviewsModalOpen: false,
  appleReviewsModalOpen: false,
  producthuntReviewsModalOpen: false,
  trustpilotReviewsModalOpen: false,
  capterraReviewsModalOpen: false,
  tripadvisorReviewsModalOpen: false,
  airbnbReviewsModalOpen: false,
  etsyReviewsModalOpen: false,
  aliExpressReviewsModalOpen: false,
  addingProducthuntAccount: Loading.INITIAL,
  addingTrustpilotAccount: Loading.INITIAL,
  addingCapterraAccount: Loading.INITIAL,
  addingTripadvisorAccount: Loading.INITIAL,
  syncingTripAdvisor: Loading.INITIAL,
  addingAirbnbAccount: Loading.INITIAL,
  syncingAirbnb: Loading.INITIAL,
  addingAmazonProduct: Loading.INITIAL,
  amazonReviewModalOpen: false,
  syncingAmazonProduct: Loading.INITIAL,
  bookingReviewModalOpen: false,
  syncingBooking: Loading.INITIAL,
};

const integrations = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    initGoogleBusinessesSearch: (state) => {
      state.googleBusinesses = [];
      state.loadingGoogleBusinesses = Loading.INITIAL;
      state.hasMoreGoogleBusinessess = false;
    },
    initAppleStoresSearch: (state) => {
      state.appleStores = [];
      state.loadingAppleStores = Loading.INITIAL;
      state.hasMoreAppleStores = false;
    },
    resetYelpSearch: (state) => {
      state.yelpBusinesses = [];
      state.hasMoreYelpLocations = false;
      state.searchingYelpBusiness = Loading.INITIAL;
    },
    toggleGoogleReviewModal: (state) => {
      state.googleReviewsModalOpen = !state.googleReviewsModalOpen;
    },
    toggleYelpReviewModal: (state) => {
      state.yelpReviewsModalOpen = !state.yelpReviewsModalOpen;
    },
    toggleProducthuntReviewModal: (state) => {
      state.producthuntReviewsModalOpen = !state.producthuntReviewsModalOpen;
    },
    toggleAppleReviewModal: (state) => {
      state.appleReviewsModalOpen = !state.appleReviewsModalOpen;
    },
    toggleTrustpilotReviewModal: (state) => {
      state.trustpilotReviewsModalOpen = !state.trustpilotReviewsModalOpen;
    },
    toggleCapterraReviewModal: (state) => {
      state.capterraReviewsModalOpen = !state.capterraReviewsModalOpen;
    },
    toggleTripadvisorReviewModal: (state) => {
      state.tripadvisorReviewsModalOpen = !state.tripadvisorReviewsModalOpen;
    },
    toggleAirbnbReviewModal: (state) => {
      state.airbnbReviewsModalOpen = !state.airbnbReviewsModalOpen;
    },
    toggleAmazonReviewModal: (state) => {
      state.amazonReviewModalOpen = !state.amazonReviewModalOpen;
    },
    toggleEtsyReviewModal: (state) => {
      state.etsyReviewsModalOpen = !state.etsyReviewsModalOpen;
    },
    toggleAliExpressReviewModal: (state) => {
      state.aliExpressReviewsModalOpen = !state.aliExpressReviewsModalOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchYelpBusiness.pending, (state) => {
      state.searchingYelpBusiness = Loading.PENDING;
    });
    builder.addCase(searchYelpBusiness.fulfilled, (state, { payload }) => {
      state.searchingYelpBusiness = Loading.SUCCESS;
      state.yelpBusinesses = [...state.yelpBusinesses, ...payload];
      state.hasMoreYelpLocations = payload.length >= YELP_LOCATIONS_PER_PAGE;
    });
    builder.addCase(searchYelpBusiness.rejected, (state) => {
      state.searchingYelpBusiness = Loading.ERROR;
    });
    builder.addCase(addYelpAccount.pending, (state) => {
      state.addingYelpAccount = Loading.PENDING;
    });
    builder.addCase(addYelpAccount.fulfilled, (state, { payload }) => {
      state.addingYelpAccount = Loading.SUCCESS;
    });
    builder.addCase(addYelpAccount.rejected, (state) => {
      state.addingYelpAccount = Loading.ERROR;
    });
    builder.addCase(syncYelpAccount.pending, (state) => {
      state.syncingYelp = Loading.PENDING;
    });
    builder.addCase(syncYelpAccount.fulfilled, (state) => {
      state.syncingYelp = Loading.SUCCESS;
    });
    builder.addCase(syncYelpAccount.rejected, (state) => {
      state.syncingYelp = Loading.ERROR;
    });
    builder.addCase(syncGoogleAccount.pending, (state) => {
      state.syncingGoogle = Loading.PENDING;
    });
    builder.addCase(syncGoogleAccount.fulfilled, (state) => {
      state.syncingGoogle = Loading.SUCCESS;
    });
    builder.addCase(syncGoogleAccount.rejected, (state) => {
      state.syncingGoogle = Loading.ERROR;
    });
    builder.addCase(getGoogleBusinesses.pending, (state) => {
      state.loadingGoogleBusinesses = Loading.PENDING;
    });
    builder.addCase(getGoogleBusinesses.fulfilled, (state, { payload }) => {
      state.loadingGoogleBusinesses = Loading.SUCCESS;
      state.googleBusinesses = [...state.googleBusinesses, ...payload];
      state.hasMoreGoogleBusinessess =
        payload.length >= GOOGLE_BUSINESSESS_PER_PAGE;
    });
    builder.addCase(getGoogleBusinesses.rejected, (state) => {
      state.loadingGoogleBusinesses = Loading.ERROR;
    });

    builder.addCase(addGoogleAccount.pending, (state) => {
      state.addingGoogleAccount = Loading.PENDING;
    });
    builder.addCase(addGoogleAccount.fulfilled, (state, { payload }) => {
      state.addingGoogleAccount = Loading.SUCCESS;
    });
    builder.addCase(addGoogleAccount.rejected, (state) => {
      state.addingGoogleAccount = Loading.ERROR;
    });

    builder.addCase(getIntegrations.pending, (state) => {
      state.getIntegrations.loading = Loading.PENDING;
    });
    builder.addCase(getIntegrations.fulfilled, (state, { payload }) => {
      state.getIntegrations.loading = Loading.SUCCESS;
      state.getIntegrations.integrations = payload;
    });
    builder.addCase(getIntegrations.rejected, (state) => {
      state.getIntegrations.loading = Loading.ERROR;
    });

    builder.addCase(deleteIntegration.pending, (state) => {
      state.deleteIntegration.loading = Loading.PENDING;
    });
    builder.addCase(deleteIntegration.fulfilled, (state, { payload }) => {
      state.deleteIntegration.loading = Loading.SUCCESS;
    });
    builder.addCase(deleteIntegration.rejected, (state) => {
      state.deleteIntegration.loading = Loading.ERROR;
    });
    //
    builder.addCase(getAppleStores.pending, (state) => {
      state.loadingAppleStores = Loading.PENDING;
    });
    builder.addCase(getAppleStores.fulfilled, (state, { payload }) => {
      state.loadingAppleStores = Loading.SUCCESS;
      state.appleStores = [...state.appleStores, ...payload];
      state.hasMoreAppleStores = payload.length >= APPLE_STORES_PER_PAGE;
    });
    builder.addCase(getAppleStores.rejected, (state) => {
      state.loadingAppleStores = Loading.ERROR;
    });

    builder.addCase(addAppleAccount.pending, (state) => {
      state.addingAppleAccount = Loading.PENDING;
    });
    builder.addCase(addAppleAccount.fulfilled, (state, { payload }) => {
      state.addingAppleAccount = Loading.SUCCESS;
    });
    builder.addCase(addAppleAccount.rejected, (state) => {
      state.addingAppleAccount = Loading.ERROR;
    });

    builder.addCase(syncAppleAccount.pending, (state) => {
      state.syncingApple = Loading.PENDING;
    });
    builder.addCase(syncAppleAccount.fulfilled, (state) => {
      state.syncingApple = Loading.SUCCESS;
    });
    builder.addCase(syncAppleAccount.rejected, (state) => {
      state.syncingApple = Loading.ERROR;
    });
    // Producthunt
    builder.addCase(addProducthuntAccount.pending, (state) => {
      state.addingProducthuntAccount = Loading.PENDING;
    });
    builder.addCase(addProducthuntAccount.fulfilled, (state, { payload }) => {
      state.addingProducthuntAccount = Loading.SUCCESS;
    });
    builder.addCase(addProducthuntAccount.rejected, (state) => {
      state.addingProducthuntAccount = Loading.ERROR;
    });
    builder.addCase(syncProducthuntAccount.pending, (state) => {
      state.syncingProducthunt = Loading.PENDING;
    });
    builder.addCase(syncProducthuntAccount.fulfilled, (state) => {
      state.syncingProducthunt = Loading.SUCCESS;
    });
    builder.addCase(syncProducthuntAccount.rejected, (state) => {
      state.syncingProducthunt = Loading.ERROR;
    });

    // Trustpilot
    builder.addCase(addTrustpilotAccount.pending, (state) => {
      state.addingTrustpilotAccount = Loading.PENDING;
    });
    builder.addCase(addTrustpilotAccount.fulfilled, (state, { payload }) => {
      state.addingTrustpilotAccount = Loading.SUCCESS;
    });
    builder.addCase(addTrustpilotAccount.rejected, (state) => {
      state.addingTrustpilotAccount = Loading.ERROR;
    });
    builder.addCase(syncTrustpilotAccount.pending, (state) => {
      state.syncingTrustpilot = Loading.PENDING;
    });
    builder.addCase(syncTrustpilotAccount.fulfilled, (state) => {
      state.syncingTrustpilot = Loading.SUCCESS;
    });
    builder.addCase(syncTrustpilotAccount.rejected, (state) => {
      state.syncingTrustpilot = Loading.ERROR;
    });

    // Capterra
    builder.addCase(addCapterraAccount.pending, (state) => {
      state.addingCapterraAccount = Loading.PENDING;
    });
    builder.addCase(addCapterraAccount.fulfilled, (state, { payload }) => {
      state.addingCapterraAccount = Loading.SUCCESS;
    });
    builder.addCase(addCapterraAccount.rejected, (state) => {
      state.addingCapterraAccount = Loading.ERROR;
    });
    builder.addCase(syncCapterraAccount.pending, (state) => {
      state.syncingCapterra = Loading.PENDING;
    });
    builder.addCase(syncCapterraAccount.fulfilled, (state) => {
      state.syncingCapterra = Loading.SUCCESS;
    });
    builder.addCase(syncCapterraAccount.rejected, (state) => {
      state.syncingCapterra = Loading.ERROR;
    });

    // Tripadvisor
    builder.addCase(addTripadvisorAccount.pending, (state) => {
      state.addingTripadvisorAccount = Loading.PENDING;
    });
    builder.addCase(addTripadvisorAccount.fulfilled, (state, { payload }) => {
      state.addingTripadvisorAccount = Loading.SUCCESS;
    });
    builder.addCase(addTripadvisorAccount.rejected, (state) => {
      state.addingTripadvisorAccount = Loading.ERROR;
    });
    builder.addCase(syncTripadvisorAccount.pending, (state) => {
      state.syncingTripAdvisor = Loading.PENDING;
    });
    builder.addCase(syncTripadvisorAccount.fulfilled, (state) => {
      state.syncingTripAdvisor = Loading.SUCCESS;
    });
    builder.addCase(syncTripadvisorAccount.rejected, (state) => {
      state.syncingTripAdvisor = Loading.ERROR;
    });

    // Airbnb
    builder.addCase(addAirbnbAccount.pending, (state) => {
      state.addingAirbnbAccount = Loading.PENDING;
    });
    builder.addCase(addAirbnbAccount.fulfilled, (state, { payload }) => {
      state.addingAirbnbAccount = Loading.SUCCESS;
    });
    builder.addCase(addAirbnbAccount.rejected, (state) => {
      state.addingAirbnbAccount = Loading.ERROR;
    });
    builder.addCase(syncAirbnbAccount.pending, (state) => {
      state.syncingAirbnb = Loading.PENDING;
    });
    builder.addCase(syncAirbnbAccount.fulfilled, (state) => {
      state.syncingAirbnb = Loading.SUCCESS;
    });
    builder.addCase(syncAirbnbAccount.rejected, (state) => {
      state.syncingAirbnb = Loading.ERROR;
    });

    // Add Amazon product reviews
    builder.addCase(addAmazonProductPage.pending, (state) => {
      state.addingAmazonProduct = Loading.PENDING;
    });
    builder.addCase(addAmazonProductPage.fulfilled, (state) => {
      state.addingAmazonProduct = Loading.SUCCESS;
    });
    builder.addCase(addAmazonProductPage.rejected, (state) => {
      state.addingAmazonProduct = Loading.ERROR;
    });

    // Sync Amazon product reviews
    builder.addCase(syncAmazonProductReviews.pending, (state) => {
      state.syncingAmazonProduct = Loading.PENDING;
    });
    builder.addCase(syncAmazonProductReviews.fulfilled, (state) => {
      state.syncingAmazonProduct = Loading.SUCCESS;
    });
    builder.addCase(syncAmazonProductReviews.rejected, (state) => {
      state.syncingAmazonProduct = Loading.ERROR;
    });

    // Sync Booking reviews
    builder.addCase(syncBookingReviews.pending, (state) => {
      state.syncingBooking = Loading.PENDING;
    });
    builder.addCase(syncBookingReviews.fulfilled, (state) => {
      state.syncingBooking = Loading.SUCCESS;
    });
    builder.addCase(syncBookingReviews.rejected, (state) => {
      state.syncingBooking = Loading.ERROR;
    });
  },
});

export const {
  initGoogleBusinessesSearch,
  initAppleStoresSearch,
  resetYelpSearch,
  toggleGoogleReviewModal,
  toggleAppleReviewModal,
  toggleYelpReviewModal,
  toggleProducthuntReviewModal,
  toggleTrustpilotReviewModal,
  toggleCapterraReviewModal,
  toggleTripadvisorReviewModal,
  toggleAirbnbReviewModal,
  toggleAmazonReviewModal,
  toggleEtsyReviewModal,
  toggleAliExpressReviewModal,
} = integrations.actions;

export const integrationsSelector = (state: RootState) => state.integrations;

export default integrations.reducer;
