import { Checkbox, Divider, InputNumber, Toggle } from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';
import styled from 'styled-components';
import FlexContainer from '../../../../shared/components/layouts/flex-container';
import PremiumFeature from '../../../../shared/components/PremiumFeature';
import { SubscriptionPlan } from '../../../../shared/types';
import { GENERIC_UPGRADE_MESSAGE } from '../../../../shared/utils/getUpgradeMessage';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleUpgradeModal } from '../../../../store/app.slice';
import { accountSelector } from '../../../userData/redux/userData.slice';
import FormRow from '../../components/form-row';
import {
  CarouselWidgetSettings,
  settingSelector,
} from '../../redux/widgetSettings.slice';
import useDispatchUpdateSiteWidgetSettings from '../../shared/hooks/useDispatchUpdateSiteWidgetSettings';

type Props = {
  widgetSettings?: CarouselWidgetSettings;
};

const BasicCustomization = styled.div`
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
`;
const CheckboxesWrapper = styled.div`
  margin-left: -10px;
`;

const BasicSettings = () => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { dispatchUpdateSiteWidgetSettings } =
    useDispatchUpdateSiteWidgetSettings();
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite || {};
  const { siteWidgetSettings } = useAppSelector(settingSelector);
  const { carouselSliderWidget: widgetSettings } = siteWidgetSettings ?? {};

  return (
    <BasicCustomization>
      <FormRow
        alignItems="center"
        justifyContent="flex-start"
        gap="50px"
        marginBottom="10px"
        style={{ height: 40 }}
      >
        <FlexContainer justifyContent="flex-start" gap="5px">
          Auto Play
          <Toggle
            size="md"
            checked={!!widgetSettings?.autoplay}
            checkedChildren="Yes"
            unCheckedChildren="No"
            onClick={() => {
              dispatchUpdateSiteWidgetSettings(
                'autoplay',
                !widgetSettings?.autoplay,
                'slider'
              );
            }}
          />
        </FlexContainer>
        {widgetSettings?.autoplay && (
          <>
            <FlexContainer justifyContent="flex-start" gap="5px">
              <span>Speed</span>
              <InputNumber
                type="number"
                step={1000}
                min={2000}
                max={10000}
                size="sm"
                value={widgetSettings?.autoPlaySpeed}
                onChange={(value) => {
                  dispatchUpdateSiteWidgetSettings(
                    'autoPlaySpeed',
                    value,
                    'slider'
                  );
                }}
                style={{ width: 100 }}
              />
            </FlexContainer>

            {/* <FlexContainer justifyContent="flex-start" gap="5px">
              Pause on hover
              <Toggle
                size="md"
                checked={
                  !!widgetSettings?.pauseOnHover
                }
                checkedChildren="Yes"
                unCheckedChildren="No"
                onClick={() => {
                  dispatchUpdateSiteWidgetSettings(
                    'pauseOnHover',
                    !widgetSettings?.pauseOnHover,
                    'slider'
                  );
                }}
              />
            </FlexContainer> */}
          </>
        )}
      </FormRow>

      <Divider />

      <CheckboxesWrapper>
        {plan === SubscriptionPlan.FREE && (
          <FormRow marginBottom="0" justifyContent="flex-start" gap="10px">
            <Checkbox
              readOnly
              onClick={() =>
                dispatch(toggleUpgradeModal(GENERIC_UPGRADE_MESSAGE))
              }
            >
              Remove ReviewsJet branding
            </Checkbox>
            <PremiumFeature />
          </FormRow>
        )}
        <FormRow marginBottom="0px">
          <Checkbox
            checked={widgetSettings?.showReviewDate}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'showReviewDate',
                checked,
                'slider'
              );
            }}
          >
            Show review date
          </Checkbox>
        </FormRow>
        <FormRow marginBottom="0px">
          <Checkbox
            checked={!widgetSettings?.hideSourceIcon}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'hideSourceIcon',
                !checked,
                'slider'
              );
            }}
          >
            Show review icons
          </Checkbox>
        </FormRow>
        <FormRow marginBottom="0">
          <Checkbox
            checked={!!widgetSettings?.acceptReviews}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'acceptReviews',
                checked,
                'slider'
              );
            }}
          >
            Enable review submission
          </Checkbox>
        </FormRow>

        <FormRow marginBottom="0">
          <Checkbox
            checked={!!widgetSettings?.showOverallRating}
            onChange={(_?: ValueType, checked?: boolean) => {
              dispatchUpdateSiteWidgetSettings(
                'showOverallRating',
                checked,
                'slider'
              );
            }}
          >
            Display overral rating
          </Checkbox>
        </FormRow>
      </CheckboxesWrapper>
    </BasicCustomization>
  );
};

export default BasicSettings;
