import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../shared/types';

export type ModalSize = 'full' | 'lg' | 'md' | 'sm' | 'xs';

type AppState = {
  instanceId: string | null;
  uid: string | null;
  loading: Loading;
  permissions: string | null;
  demoMode: boolean | null;
  upgradeMessage: string | null;
  platformModalSize: ModalSize;
  upgradePlanId: 'basic' | 'professional' | 'advanced';
  billingCycle: 'MONTHLY' | 'YEARLY';
  showChangelogModal: boolean;
};

const initialState: AppState = {
  instanceId: null,
  uid: null,
  permissions: null,
  loading: Loading.INITIAL,
  demoMode: null,
  upgradeMessage: null,
  platformModalSize: 'sm',
  upgradePlanId: 'professional',
  billingCycle: 'YEARLY',
  showChangelogModal: false,
};

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleUpgradeModal: (state, { payload }) => {
      state.upgradeMessage = payload;
    },
    setPlatformModalSize: (state, { payload }) => {
      state.platformModalSize = payload;
    },
    setBillingData: (state, { payload }) => {
      state.upgradePlanId = payload.upgradePlanId;
      state.billingCycle = payload.billingCycle;
    },
    toggleChangelogModal: (state, { payload }) => {
      state.showChangelogModal = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  toggleUpgradeModal,
  setPlatformModalSize,
  setBillingData,
  toggleChangelogModal,
} = app.actions;

export default app.reducer;
