export enum Loading {
  INITIAL = 'initial',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ReviewStatus {
  ALL = 'all',
  PENDING = 'pending',
  PUBLISHED = 'published',
}

export enum ReviewerPlatform {
  // FACEBOOK = 'facebook',
  GOOGLE = 'google',
  YELP = 'yelp',
  // AMAZON = 'amazon',
  WIDGET_REVIEW = 'widgetReview',
  PRODUCT_REVIEW = 'productReview',
  REVIEWSJET_REVIEW = 'via-reviewsjet',
  APPLE = 'apple',
  PRODUCTHUNT = 'producthunt',
  TRUSTPILOT = 'trustpilot',
  CAPTERRA = 'capterra',
  TRIPADVISOR = 'tripadvisor',
  AIRBNB = 'airbnb',
  AMAZON = 'amazon',
  ETSY = 'etsy',
  ALI_EXPRESS = 'aliexpress',
}

export type ReviewQueryParams = {
  pageNumber?: number;
  sort?: 'desc' | 'asc';
  limit?: number;
  status?: ReviewStatus | null;
  rating?: string | null;
  search?: string;
  sortBy?: string;
  platform?: ReviewerPlatform | null;
  filterReviewsJetReviews?: ReviewerPlatform;
  missingReplies?: string;
};

export enum SubscriptionPlan {
  FREE = 'free',
  PRO = 'professional',
  ADVANCED = 'advanced',
  ONETIME_PRO = 'onetime-pro',
  ONETIME_ADVANCED = 'one-time-advanced',
  PREMIUM = 'premium',
  ONETIME_PREMIUM = 'onetime-premium',
}

export enum PlanCycle {
  NO_CYCLE = 'NO_CYCLE',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  ONE_TIME = 'ONE_TIME',
}

export enum RemoteFilePath {
  WIX_FEEDBACK = 'wixFeedback',
  THANK_YOU = 'thankYou',
  PRODUCT_REVIEW = 'productReview',
}

export type YelpLocation = {
  title: string;
  link: string;
  phone: string;
  thumbnail: string;
  placeId: string;
};

export enum ThirdPartyEngine {
  YELP = 'yelp',
  GOOGLE = 'google',
  YELP_REVIEWS = 'yelp_reviews',
}

export type GoogleConnectFormData = {
  start?: number;
  hl: string;
  gpsCoordinates: string;
  businessAddress: string;
};

export type GoogleBusiness = {
  title: string;
  phone: string;
  thumbnail: string;
  placeId: string;
  dataId: string;
  address: string;
  language: string;
  pageTitle: string;
};

export type OneTimeProductReviewCustomer = {
  name: string;
  product: string;
  email: string;
};

export type OneTimeProductReviewData = {
  title: string;
  message: string;
  status: string;
  sendInterval: number;
  customers: OneTimeProductReviewCustomer[];
};

export type PaginatedResponse<T> = {
  docs: T[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
};

export type YelpLocationFormData = {
  name: string;
  address: string;
};

export type AppleConnectFormData = {
  page?: number;
  country: string;
  lang: string;
  search: string;
};

export interface IAppleStore {
  title: string;
  description: string;
  link: string;
  serpapi_product_link: string;
  serpapi_reviews_link: string;
  product_id: string;
  language: string;
  country: string;
  logos: { size: string; link: string }[];
}

export type WidgetType =
  | 'masonry'
  | 'grid'
  | 'slider'
  | 'marquee'
  | 'modal'
  | 'list'
  | 'product';

export type SettingsTabs =
  | 'border'
  | 'shadow'
  | 'background'
  | 'text'
  | 'other';
