import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Button, Input, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { SubscriptionPlan } from '../../../../../shared/types';
import { useAppSelector } from '../../../../../store';
import { ProductList } from '../../../../product/compoments/product-modal/product-list-modal';
import { TransformedProduct } from '../../../../product/types';
import { accountSelector } from '../../../../userData/redux/userData.slice';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;
const StyledMessage = styled(Message)`
  margin-bottom: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  padding-left: 30px;
  padding-right: 30px;
`;

const SetProduct = ({
  onNext,
  onPrevious,
}: {
  onNext: (product: TransformedProduct) => void;
  onPrevious?: () => void;
}) => {
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite ?? {};
  // const ref = createRef<HTMLInputElement>();
  const { t } = useTranslation('common');

  return (
    <>
      <ModalHeader
        title="Select a product"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            {plan === SubscriptionPlan.FREE && (
              <StyledMessage type="info" bordered showIcon>
                <span>
                  <b>Current plan: FREE</b>. A maximum of <b>15 reviews</b> will
                  be imported.
                </span>
              </StyledMessage>
            )}
            <StyledLabel>
              Which product page would you like to display these reviews on?
            </StyledLabel>

            <ProductList
              title={t('reviews.import-modal.select-product-to-review')}
              showPreloader={true}
              onProductSelect={(product) => {
                onNext(product);
                // setValue('productId', product.id);
                // setSelectedProduct(product);
                // setShowProductModal(false);
                // setShowPreloader(false);
                // clearErrors('productId');
                // setSize('xs');
              }}
            />

            <FlexContainer
              justifyContent="flex-start"
              gap="15px"
              style={{ paddingTop: 20 }}
            >
              <Button onClick={onPrevious}>
                <FlexContainer gap="5px">
                  <MdKeyboardArrowLeft />
                  Back
                </FlexContainer>
              </Button>
              {/* <ContinueButton
                appearance={
                  addingTrustpilotAccount === Loading.PENDING
                    ? 'subtle'
                    : 'primary'
                }
                size="md"
                onClick={connectTrustpilot}
              >
                {addingTrustpilotAccount === Loading.PENDING
                  ? t('shared.please-wait')
                  : 'Import reviews'}
              </ContinueButton> */}
            </FlexContainer>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default SetProduct;
