import { axiosPrivate as axios } from '../services/axiosPrivate';
import removeTrailingSlash from '../shared/utils/removeTrailingSlash';

export default async function getSiteToken() {
  return axios.post(
    `${removeTrailingSlash(
      process.env.REACT_APP_REVIEWS_JET_API!
    )}/account/get-token`
  );
}
