import { FaRegStar } from 'react-icons/fa';
import { TbSeo } from 'react-icons/tb';

import { FaHeart, FaRegHeart, FaStar } from 'react-icons/fa6';
import { GoMegaphone } from 'react-icons/go';
import {
  IoCloudDownloadOutline,
  IoCloudDownloadSharp,
  IoRocket,
  IoRocketOutline,
} from 'react-icons/io5';
import { LuSettings } from 'react-icons/lu';
import { MdOutlineWidgets, MdWidgets } from 'react-icons/md';
import { RiMailSendFill, RiMailSendLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { userAccountConfigSelector } from '../../../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { toggleChangelogModal } from '../../../../store/app.slice';
import ReviewsJetCrownLogo from '../../ReviewsJetCrownLogo';
import Logo from '../../logo';
import FlexContainer from '../flex-container';

const Wrapper = styled.div<{ $collapsed: boolean; $hideSidebar: boolean }>`
  height: 100vh;
  flex: none;
  width: 85px;
  transition: width 0.15s;
  position: fixed;
  z-index: 9;
  ${({ $hideSidebar }) => {
    if ($hideSidebar) {
      return css`
        display: none;
      `;
    }
    return css`
      border-right: 1px solid #e4e4e4;
    `;
  }};
`;

const MenuWrapper = styled.div`
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  padding-bottom: 10px;
`;
const LogoWrapper = styled.div<{ collapsed: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* width: ${({ collapsed }) => (!!collapsed ? '77px;' : '265px;')}; */
  padding: 20px;
  border-bottom: 1px solid #e4e4e4;
  transition: width 0.15s;
  background-color: #fff;
`;
const StyledFlexContainer = styled(FlexContainer)<{ collapsed: boolean }>`
  color: #000;
  cursor: pointer;
  margin-bottom: ${({ collapsed }) => (collapsed ? '-10px' : '-9px')};
`;
const Divider = styled.hr`
  margin: 8px 0;
`;

const MainMenu = styled.div<{ $collapsed: boolean }>`
  display: flex;
  z-index: 10;
  align-items: center;
  flex-direction: column;
  padding: 10px 8px 0;
  max-height: 85%;
  ${({ $collapsed }) =>
    !$collapsed &&
    css`
      overflow-y: auto;
      padding-bottom: 50px;
    `}
`;
const OtherMenu = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 8px 10px;
  position: relative;

  ${({ $collapsed }) =>
    !!$collapsed &&
    css`
      /* &:hover::after {
        content: attr(data-title);
        padding: 5px;
        position: absolute;
        top: 3px;
        left: 60px;
        background: #000;
        color: #fff;
        z-index: 9999999;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
      } */
    `}
`;
const MenuCategory = styled.div<{ $collapsed: boolean }>`
  color: #000;
  opacity: 1;
  font-weight: bold;
  color: #737373;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  /* border-bottom: 1px solid #e0e0e0; */
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  &:hover {
    /* color: #324fbe; */
  }
  &.active {
    /* color: #324fbe; */
  }
  padding: 10px 0px 10px 10px;
  justify-content: ${({ $collapsed }) => ($collapsed ? 'center' : '')};
`;

const MenuItem = styled(Link)<{ $collapsed: boolean }>`
  position: relative;
  opacity: 1;
  color: #000000e3;
  font-size: ${({ $collapsed }) => (!!$collapsed ? '11px' : '14px')};
  display: flex;
  flex-direction: ${({ $collapsed }) => (!!$collapsed ? 'column' : 'row')};
  gap: ${({ $collapsed }) => (!!$collapsed ? '3px' : '10px')};
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  margin-bottom: 2px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  &:hover {
    color: #324fbe;
    background-color: #ebeef9;
  }
  &.active {
    color: #324fbe;
    background-color: #ebeef9;
    font-weight: bold;
  }
  padding: ${({ $collapsed }) => (!!$collapsed ? '7px 0' : '7px 10px')};
  justify-content: ${({ $collapsed }) => ($collapsed ? 'center' : '')};
  ${({ $collapsed }) =>
    !!$collapsed &&
    css`
      width: 75px;
    `}/* ${({ $collapsed }) =>
    !!$collapsed &&
    css`
      &:hover::after {
        content: attr(data-title);
        padding: 5px;
        position: absolute;
        top: 3px;
        left: 40px;
        background: #000;
        color: #fff;
        z-index: 9999999;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;
      }
    `} */
`;

const Sidebar = ({
  hideSidebar,
  collapsedSidebar,
}: {
  hideSidebar: boolean;
  collapsedSidebar: boolean;
  toggleSidebar: (value: boolean) => void;
}) => {
  const dispach = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const siteConfig = useAppSelector(userAccountConfigSelector);

  if (!siteConfig) {
    return null;
  }

  return (
    <>
      <Wrapper $collapsed={collapsedSidebar} $hideSidebar={hideSidebar}>
        <LogoWrapper collapsed={collapsedSidebar}>
          <StyledFlexContainer
            justifyContent="flex-start"
            alignItems="center"
            gap="10px"
            role="presentation"
            onClick={() => navigate('/manage-reviews')}
            collapsed={collapsedSidebar}
          >
            {collapsedSidebar ? <ReviewsJetCrownLogo /> : <Logo />}
          </StyledFlexContainer>
        </LogoWrapper>
        <MenuWrapper>
          <MainMenu $collapsed={collapsedSidebar}>
            {!collapsedSidebar && (
              <MenuCategory $collapsed={collapsedSidebar}>Manage</MenuCategory>
            )}

            <div>
              <MenuItem
                to="/manage-reviews"
                $collapsed={collapsedSidebar}
                className={pathname === '/manage-reviews' ? 'active' : ''}
                data-title="Manage reviews"
              >
                {pathname === '/manage-reviews' ? (
                  <FaStar size={18} />
                ) : (
                  <FaRegStar size={18} />
                )}
                Reviews
              </MenuItem>

              <MenuItem
                to="/share-link"
                $collapsed={collapsedSidebar}
                className={pathname === '/share-link' ? 'active' : ''}
                data-title="Links"
              >
                {pathname === '/share-link' ? (
                  <IoRocket size={18} />
                ) : (
                  <IoRocketOutline size={18} />
                )}
                Collect
              </MenuItem>

              <MenuItem
                to="/wall-of-love"
                $collapsed={collapsedSidebar}
                className={pathname === '/wall-of-love' ? 'active' : ''}
                data-title="Links"
              >
                {pathname === '/wall-of-love' ? (
                  <FaHeart size={18} />
                ) : (
                  <FaRegHeart size={18} />
                )}
                Love
              </MenuItem>

              <MenuItem
                to="/import-reviews"
                $collapsed={collapsedSidebar}
                data-title="Import reviews"
                className={pathname === '/import-reviews' ? 'active' : ''}
              >
                {pathname === '/import-reviews' ? (
                  <IoCloudDownloadSharp size={18} />
                ) : (
                  <IoCloudDownloadOutline size={18} />
                )}
                Import
              </MenuItem>

              <Divider />

              {!collapsedSidebar && (
                <MenuCategory
                  $collapsed={collapsedSidebar}
                  className={pathname === '/reviews' ? 'active' : ''}
                  data-title="Collect reviews"
                >
                  {!collapsedSidebar && 'Collect'}
                </MenuCategory>
              )}
              <MenuItem
                to="/review-requests"
                data-title="Review requests"
                $collapsed={collapsedSidebar}
                className={
                  pathname.includes('/review-requests') ? 'active' : ''
                }
              >
                {pathname === '/review-requests' ? (
                  <RiMailSendFill size={18} />
                ) : (
                  <RiMailSendLine size={18} />
                )}

                {collapsedSidebar ? 'Emails' : 'Ask for reviews'}
              </MenuItem>
              <Divider />
              {!collapsedSidebar && (
                <MenuCategory
                  $collapsed={collapsedSidebar}
                  className={pathname === '/reviews' ? 'active' : ''}
                >
                  {!collapsedSidebar && 'Widgets'}
                </MenuCategory>
              )}

              <MenuItem
                to="/select-widgets"
                data-title="ReviewsJet widgets"
                $collapsed={collapsedSidebar}
                className={
                  [
                    '/select-widgets',
                    '/widgets/masonry',
                    '/widgets/grid',
                    '/widgets/list',
                    '/widgets/slider',
                    '/widgets/marquee',
                    '/widgets/modal-widget',
                    '/widgets/product',
                  ].includes(pathname)
                    ? 'active'
                    : ''
                }
              >
                {[
                  '/select-widgets',
                  '/widgets/masonry',
                  '/widgets/grid',
                  '/widgets/list',
                  '/widgets/slider',
                  '/widgets/marquee',
                  '/widgets/modal-widget',
                  '/widgets/product',
                ].includes(pathname) ? (
                  <MdWidgets size={18} />
                ) : (
                  <MdOutlineWidgets size={18} />
                )}

                {collapsedSidebar ? 'Widgets' : 'ReviewsJet widgets'}
              </MenuItem>
              {/* <MenuItem
                to="/widgets/wall-of-love"
                data-title="Wall of love widget"
                $collapsed={collapsedSidebar}
                className={pathname === '/widgets/wall-of-love' ? 'active' : ''}
              >
                <PiWallDuotone size={18} />
                {!collapsedSidebar && 'Wall of love'}
              </MenuItem>
              <MenuItem
                to="/widgets/carousel"
                $collapsed={collapsedSidebar}
                data-title="Carousel widget"
                className={pathname === '/widgets/carousel' ? 'active' : ''}
              >
                <MdOutlineViewCarousel size={18} />
                {!collapsedSidebar && 'Carousel'}
              </MenuItem>
              <MenuItem
                to="/widgets/modal-reviews"
                $collapsed={collapsedSidebar}
                data-title="Modal widget"
                className={
                  pathname === '/widgets/modal-reviews' ? 'active' : ''
                }
              >
                <MdOutlineSmartScreen size={18} />
                {!collapsedSidebar && 'Modal widget'}
              </MenuItem>
              <MenuItem
                to="/widgets/product-widget"
                $collapsed={collapsedSidebar}
                data-title="Product reviews widget"
                className={
                  pathname === '/widgets/product-widget' ? 'active' : ''
                }
              >
                <TbShirt size={18} />
                {!collapsedSidebar && 'Product reviews widget'}
              </MenuItem> */}

              <Divider />
              {!collapsedSidebar && (
                <MenuCategory
                  $collapsed={collapsedSidebar}
                  className={
                    pathname === '/seo/business-rich-snippet' ? 'active' : ''
                  }
                >
                  {!collapsedSidebar && 'SEO'}
                </MenuCategory>
              )}

              <MenuItem
                to="/seo/business-rich-snippet"
                $collapsed={collapsedSidebar}
                data-title="Google Rich Snippets"
                className={
                  pathname === '/seo/business-rich-snippet' ? 'active' : ''
                }
              >
                <TbSeo size={18} />
                SEO
              </MenuItem>
            </div>
          </MainMenu>

          {/* <CreateSpace isOpen={showModal} onClose={handleCloseModal} /> */}

          {/* <Button appearance="primary" onClick={handleOpenModal}>
            <FlexContainer justifyContent="flex-start">
              <IoMdAdd /> <span>Create Space</span>
            </FlexContainer>
          </Button> */}

          <OtherMenu $collapsed={collapsedSidebar}>
            <MenuItem
              to=""
              $collapsed={collapsedSidebar}
              data-title="Changelog"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispach(toggleChangelogModal(true));
              }}
            >
              <GoMegaphone size={18} />
              Changelog
            </MenuItem>
            <MenuItem
              to="/settings"
              $collapsed={collapsedSidebar}
              data-title="Settings"
              className={pathname === '/settings' ? 'active' : ''}
            >
              <LuSettings size={18} />
              Settings
            </MenuItem>
          </OtherMenu>
        </MenuWrapper>
      </Wrapper>
    </>
  );
};

export default Sidebar;
