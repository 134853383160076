import { unwrapResult } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { useRef } from 'react';
import { MdSaveAlt } from 'react-icons/md';
import { Button, Input } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import VideoTranslations from '../../../../../shared/components/VideoTranslations';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import FormRow from '../../../components/form-row';
import { updateWidgetsSettings } from '../../../redux/action';
import {
  siteWidgetSettingsSelector,
  updateSiteWidgetSettings,
} from '../../../redux/widgetSettings.slice';

const Title = styled.h4`
  color: #000;
  font-weight: bold;
  margin: 0%;
  font-size: 18px;
`;

const Wrapper = styled.div`
  max-height: 400px;
  margin-top: 20px;
  overflow: auto;
`;

const Header = styled(FlexContainer)`
  margin-top: 0px;
`;
const Label = styled.label`
  display: block;
  color: black;
  margin-bottom: 5px;
`;
const Code = styled.code`
  font-size: 13px;
  color: crimson;
`;

const Translation = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector(siteWidgetSettingsSelector);
  const { showSuccessToast } = useNotification();
  const { saving } = useAppSelector(
    (state) => state.setting.updateWidgetSettings
  );

  const { translation } = settings ?? {};

  const updateTranslation = useRef(
    debounce((value: string, payload: object) => {
      dispatch(updateSiteWidgetSettings(payload));
    }, 300)
  ).current;

  const handleSaveTranslation = () => {
    dispatch(updateWidgetsSettings())
      .then(unwrapResult)
      .then(() => {
        onClose();
        showSuccessToast('Settings saved successfully.');
      })
      .catch();
  };

  return (
    <>
      <Header justifyContent="space-between">
        <Title>Translation</Title>
      </Header>
      <Wrapper>
        <FormRow stack alignItems="flex-start">
          <Label>What Our Customers Are Saying:</Label>
          <Input
            maxLength={56}
            placeholder="What Our Customers Are Saying"
            defaultValue={translation?.happyCustomersTitle}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  happyCustomersTitle: value,
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>
            Based on <Code>&#123;totalReview&#125;</Code> verified reviews
          </Label>
          <Input
            placeholder="Based on &#123;totalReview&#125; verified reviews"
            defaultValue={translation?.widgetTitle}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: { ...settings?.translation, widgetTitle: value },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Write a review</Label>
          <Input
            placeholder="Write a review"
            defaultValue={translation?.reviewForm?.addReview}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    addReview: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Enter review title (Optional)</Label>
          <Input
            placeholder="Enter review title (Optional)"
            defaultValue={translation?.reviewForm.enterReviewTitle}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    enterReviewTitle: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Load More Reviews</Label>
          <Input
            placeholder="Load More Reviews"
            defaultValue={translation?.loadMoreReviews}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  loadMoreReviews: value,
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Submit your review on:</Label>
          <Input
            placeholder="Submit your review on:"
            defaultValue={translation?.reviewForm.submitYourReviewOn}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    submitYourReviewOn: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Profile Picture (Optional)</Label>
          <Input
            placeholder="Profile Picture (Optional)"
            defaultValue={translation?.reviewForm.profilePicture}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    profilePicture: value,
                  },
                },
              })
            }
          />
        </FormRow>

        <FormRow stack alignItems="flex-start">
          <Label>Click here to upload</Label>
          <Input
            placeholder="Click here to upload"
            defaultValue={translation?.reviewForm.clickHereToUpload}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    clickHereToUpload: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Enter your review</Label>
          <Input
            placeholder="Enter your review"
            defaultValue={translation?.reviewForm?.messagePlaceholder}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    messagePlaceholder: value,
                  },
                },
              })
            }
          />
        </FormRow>

        <FormRow stack alignItems="flex-start">
          <Label>Your name</Label>
          <Input
            placeholder="Your name"
            defaultValue={translation?.reviewForm?.namePlaceHolder}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    namePlaceHolder: value,
                  },
                },
              })
            }
          />
        </FormRow>

        <FormRow stack alignItems="flex-start">
          <Label>Your email address</Label>
          <Input
            placeholder="Your email address"
            defaultValue={translation?.reviewForm?.emailPlaceHolder}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    emailPlaceHolder: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Submit</Label>
          <Input
            placeholder="Submit"
            defaultValue={translation?.reviewForm?.submitButtonText}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    submitButtonText: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Cancel</Label>
          <Input
            placeholder="Cancel"
            defaultValue={translation?.reviewForm?.cancel}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  reviewForm: {
                    ...settings?.translation?.reviewForm,
                    cancel: value,
                  },
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>Read more</Label>
          <Input
            placeholder="Read more"
            defaultValue={translation?.readMore}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  readMore: value,
                },
              })
            }
          />
        </FormRow>
        <FormRow stack alignItems="flex-start">
          <Label>View review</Label>
          <Input
            placeholder="View review"
            defaultValue={translation?.viewReview}
            onChange={(value) =>
              updateTranslation(value, {
                ...settings,
                translation: {
                  ...settings?.translation,
                  viewReview: value,
                },
              })
            }
          />
        </FormRow>

        <VideoTranslations />
      </Wrapper>

      <FlexContainer justifyContent="flex-end" gap="15px">
        {saving !== Loading.PENDING && (
          <Button appearance="subtle" onClick={onClose}>
            Cancel
          </Button>
        )}

        <Button
          appearance={saving === Loading.PENDING ? 'default' : 'primary'}
          onClick={handleSaveTranslation}
        >
          <FlexContainer gap="5px">
            <MdSaveAlt size={16} />

            {saving === Loading.PENDING ? 'Saving...' : 'Save'}
          </FlexContainer>
        </Button>
      </FlexContainer>
    </>
  );
};

export default Translation;
