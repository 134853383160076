import { unwrapResult } from '@reduxjs/toolkit';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, ButtonToolbar, Form, Schema } from 'rsuite';
import styled from 'styled-components';
import FlexContainer from '../../../shared/components/layouts/flex-container';
import ReviewsJetCrownLogo from '../../../shared/components/ReviewsJetCrownLogo';
import { useNotification } from '../../../shared/hooks/useNotification';
import { Loading } from '../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import {
  updatePassword,
  verifyPasswordResetToken,
} from '../../userData/redux/action';
import {
  AuthForm,
  AuthFormGroup,
  AuthIconWrapper,
  AuthLabel,
  AuthTextField,
  AuthTitle,
} from '../components';

const StyledFlexContainer = styled(FlexContainer)`
  border-top: 1px solid #eeeeee;
  padding: 10px 0;
  min-width: 350px;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  max-width: 500px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  @media (max-width: 530px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;
const LogoCrown = styled.img`
  height: 50px;
  /* box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1); */
  border-radius: 100%;
  /* border: 2px solid #eaeaea; */
`;

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType()
    .isRequired('This field is required.')
    .addRule((password) => {
      let strength = 0;

      if (password.match(/[a-z]+/)) {
        strength += 1;
      }

      if (password.match(/[0-9]+/)) {
        strength += 1;
      }

      if (password.length >= 5) {
        strength += 1;
      }

      if (strength < 3) {
        return false;
      }

      return true;
    }, 'Password should contain at least a letter and a number.'),
  verifyPassword: StringType()
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }

      return true;
    }, 'The two passwords do not match')
    .isRequired('This field is required.'),
});

const SetPasswordPage = () => {
  const { token } = useParams<{ token: string }>();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { showSuccessToast, showErrorToast } = useNotification();
  const [visible, setVisible] = useState(false);
  const formRef = useRef<{ check: () => void }>(null);
  const {
    updatePassword: { loading },
    verifyPasswordResetToken: { loading: verifyingToken },
  } = useAppSelector((state) => state.userData);

  const [formValue, setFormValue] = useState({
    password: '',
  });

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!formRef.current?.check()) {
        return;
      }

      if (!token) {
        return;
      }

      dispatch(updatePassword({ password: formValue.password, token }))
        .then(unwrapResult)
        .then(() => {
          showSuccessToast('Password updated! Please login now.');
          setSubmitted(true);
        })
        .catch(() => {
          showErrorToast('An error occurred. Please try it again.');
        });
    },
    [formValue, token]
  );

  useEffect(() => {
    if (token) {
      dispatch(verifyPasswordResetToken(token));
    }
  }, [token]);

  if (verifyingToken === Loading.PENDING) {
    return <p>Please wait....</p>;
  }

  // if (verifyingToken === Loading.ERROR) {
  //   return (
  //     <Wrapper style={{ maxWidth: 650 }}>
  //       <ErrorPage
  //         onRetryClick={() => {
  //           if (token) {
  //             dispatch(verifyPasswordResetToken(token));
  //           }
  //         }}
  //         showFooter={false}
  //       />

  //       <StyledFlexContainer gap="10px">
  //         <Link to="/login">Go back to sign-in</Link>
  //       </StyledFlexContainer>
  //     </Wrapper>
  //   );
  // }

  return (
    <Wrapper>
      <a
        href="https://www.reviewsjet.com"
        style={{ marginBottom: 30, display: 'block' }}
      >
        <ReviewsJetCrownLogo />
      </a>

      <>
        {submitted ? (
          <>
            <AuthTitle>Success!</AuthTitle>
            <p>
              Your password has been successfully updated. You may login now.
            </p>
            <StyledFlexContainer gap="10px">
              <Link to="/login">Go back to sign-in</Link>
            </StyledFlexContainer>
          </>
        ) : (
          <>
            <AuthTitle>Set password</AuthTitle>

            <AuthForm
              fluid
              ref={formRef}
              onChange={setFormValue}
              formValue={formValue}
              model={model}
            >
              <AuthFormGroup controlId="password">
                <AuthIconWrapper onClick={toggleVisibility}>
                  {visible ? <EyeIcon /> : <EyeSlashIcon />}
                </AuthIconWrapper>
                <AuthLabel>Password</AuthLabel>
                <AuthTextField
                  isPassword
                  name="password"
                  type={visible ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder={visible ? 'Enter password' : '*************'}
                />
              </AuthFormGroup>

              <AuthFormGroup controlId="password">
                <AuthLabel>Confirm Password</AuthLabel>
                <AuthTextField
                  name="verifyPassword"
                  type={visible ? 'text' : 'password'}
                  autoComplete="off"
                  placeholder={visible ? 'Enter password' : '*************'}
                />
              </AuthFormGroup>

              <Form.Group>
                <ButtonToolbar>
                  <Button
                    block
                    size="lg"
                    color="violet"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={loading === Loading.PENDING}
                    appearance={
                      loading === Loading.PENDING ? 'default' : 'primary'
                    }
                  >
                    {loading === Loading.PENDING
                      ? 'Please wait...'
                      : 'Set new password'}
                  </Button>
                </ButtonToolbar>
              </Form.Group>
            </AuthForm>
          </>
        )}
      </>
    </Wrapper>
  );
};

export default SetPasswordPage;
