import { createSlice } from '@reduxjs/toolkit';
import { Loading } from '../../../shared/types';
import { getBusinessInfo } from './actions';

export type BusinessInfo = {
  siteId: string;
  logo: string;
  name: string;
  about: string;
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  website: string;
  email: string;
  telephone: string;
  x: string;
  facebook: string;
  instagram: string;
};

type BusinessInfoState = {
  loading: Loading;
  data: BusinessInfo | null;
};

const initialState: BusinessInfoState = {
  loading: Loading.INITIAL,
  data: null,
};

const businessInfo = createSlice({
  name: 'businessInfo',
  initialState,
  reducers: {
    setBusinessInfo: (state, { payload }: { payload: BusinessInfo | null }) => {
      if (state.data) {
        state.data = { ...state.data, ...payload };
      } else {
        state.data = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBusinessInfo.pending, (state) => {
      state.loading = Loading.PENDING;
    });
    builder.addCase(getBusinessInfo.rejected, (state) => {
      state.loading = Loading.ERROR;
    });
    builder.addCase(getBusinessInfo.fulfilled, (state, { payload }) => {
      state.loading = Loading.SUCCESS;
      state.data = payload.businessInfo;
    });
  },
});

export const { setBusinessInfo } = businessInfo.actions;

export default businessInfo.reducer;
