import { createSelector, createSlice } from '@reduxjs/toolkit';
import {
  Loading,
  PaginatedResponse,
  ReviewStatus,
  ReviewerPlatform,
} from '../../../shared/types';
import { RootState } from '../../../store';
import { Product } from '../../product/types';
import {
  bulkDeleteReviews,
  bulkPublishReviews,
  createReview,
  deleteReview,
  fetchReviews,
  getReviewsUsage,
  submitAReply,
  toggleReviewStatus,
  updateReviewerAvatar,
  uploadReviewerAvatar,
} from './action';

export enum ReviewHeaders {
  REVIEW = 'REVIEW',
  RATING = 'RATING',
  REVIEW_DATE = 'REVIEW_DATE',
  HELPFUL_COUNT = 'HELPFUL_COUNT',
  STATUS = 'STATUS',
  REVIEWER_NAME = 'REVIEWER_NAME',
  REVIEWER_AVATAR = 'REVIEWER_AVATAR',
}

export type Reviewer = {
  _id: string;
  instanceId: string;
  email: string;
  fullName: string;
  avatar: string | null;
  created_at: string;
  updated_at: string;
};

export type ReviewerLocation = {
  ipAddress: string;
  city: string;
  region: string;
  regionIsoCode: string;
  countryCode: string;
};

export type Review = {
  instanceId: string;
  title?: string;
  content: string;
  rating: number;
  status: ReviewStatus;
  reviewer: Reviewer;
  verified: boolean;
  platform: ReviewerPlatform;
  helpfulCount: number;
  pictures: string[];
  reviewDate: string;
  _id: string;
  id: string;
  created_at: string;
  updated_at: string;
  product: Product | null;
  reply: { content: string } | null;
  fromWidget?: boolean;
  link?: string;
  video?: string;
  location: ReviewerLocation | null;
  videoPoster?: string;
  type: 'text' | 'video';
};

export type ReviewFormData = {
  name: string;
  rating: number;
  email: string;
  message: string;
  platform?: ReviewerPlatform;
  status?: ReviewStatus;
  reviewDate: string | null;
  product?: string;
  reviewImg?: string;
  reviewerPic?: string;
  title: string;
  reviewerCountry: string;
};

export type ModalSize = 'full' | 'lg' | 'md' | 'sm' | 'xs';

type ReviewState = {
  reviews: {
    loading: Loading;
    data: PaginatedResponse<Review> | null;
  };
  toggleReviewStatus: {
    reviewId: string | null;
    loading: Loading;
  };
  deleteReview: {
    reviewId: string | null;
    loading: Loading;
  };
  submitReply: {
    loading: Loading;
    error?: string;
    reviewId: string | null;
  };
  uploadAvatar: { reviewId: string | null; loading: Loading };
  createReview: {
    loading: Loading;
    error: string | null;
  };
  addReview: {
    openModal: boolean;
  };
  bulkDeleteReviews: {
    loading: Loading;
  };
  bulkPublishReviews: {
    loading: Loading;
  };
  reviewUsage: {
    loading: Loading;
    published: number;
    textReviewsPublished: number;
    videoReviewsPublished: number;
    pending: number;
    total: number;
    withoutReplies: number;
  };
};

const initialState: ReviewState = {
  reviews: {
    loading: Loading.INITIAL,
    data: null,
  },
  toggleReviewStatus: {
    reviewId: null,
    loading: Loading.INITIAL,
  },
  deleteReview: {
    reviewId: null,
    loading: Loading.INITIAL,
  },
  submitReply: {
    reviewId: null,
    loading: Loading.INITIAL,
  },
  uploadAvatar: { reviewId: null, loading: Loading.INITIAL },
  createReview: {
    loading: Loading.INITIAL,
    error: null,
  },
  addReview: {
    openModal: false,
  },
  bulkDeleteReviews: {
    loading: Loading.INITIAL,
  },
  bulkPublishReviews: {
    loading: Loading.INITIAL,
  },
  reviewUsage: {
    loading: Loading.INITIAL,
    textReviewsPublished: 0,
    videoReviewsPublished: 0,
    pending: 0,
    total: 0,
    published: 0,
    withoutReplies: 0,
  },
};

const reviews = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    updateReviews: (state, { payload }) => {
      if (state.reviews.data?.docs) {
        state.reviews.data.docs = payload;
      }
    },
    toggleAddReviewModal: (state) => {
      state.addReview.openModal = !state.addReview.openModal;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchReviews.pending,
      (
        state,
        {
          meta: {
            arg: { showPreloader },
          },
        }
      ) => {
        if (!!showPreloader) {
          state.reviews.loading = Loading.PENDING;
        }
      }
    );
    builder.addCase(
      fetchReviews.fulfilled,
      (state, { payload, meta: { arg } }) => {
        state.reviews.loading = Loading.SUCCESS;
        state.reviews.data = payload.reviews;
      }
    );
    builder.addCase(fetchReviews.rejected, (state) => {
      state.reviews.loading = Loading.ERROR;
    });
    builder.addCase(
      toggleReviewStatus.pending,
      (
        state,
        {
          meta: {
            arg: { id },
          },
        }
      ) => {
        state.toggleReviewStatus.loading = Loading.PENDING;
        state.toggleReviewStatus.reviewId = id;
      }
    );
    builder.addCase(toggleReviewStatus.rejected, (state) => {
      state.toggleReviewStatus.loading = Loading.ERROR;
    });
    builder.addCase(toggleReviewStatus.fulfilled, (state) => {
      state.toggleReviewStatus.loading = Loading.SUCCESS;
    });

    builder.addCase(deleteReview.pending, (state) => {
      state.deleteReview.loading = Loading.PENDING;
    });
    builder.addCase(deleteReview.rejected, (state) => {
      state.deleteReview.loading = Loading.ERROR;
    });
    builder.addCase(deleteReview.fulfilled, (state) => {
      state.deleteReview.loading = Loading.SUCCESS;
    });
    builder.addCase(submitAReply.pending, (state) => {
      state.submitReply.loading = Loading.PENDING;
    });
    builder.addCase(submitAReply.rejected, (state, { payload }) => {
      state.submitReply.loading = Loading.ERROR;
      state.submitReply.error = payload;
    });
    builder.addCase(submitAReply.fulfilled, (state) => {
      state.submitReply.loading = Loading.SUCCESS;
    });

    builder.addCase(uploadReviewerAvatar.pending, (state) => {
      state.uploadAvatar.loading = Loading.PENDING;
    });
    builder.addCase(uploadReviewerAvatar.rejected, (state, { payload }) => {
      state.uploadAvatar.loading = Loading.ERROR;
    });

    builder.addCase(updateReviewerAvatar.pending, (state) => {
      state.uploadAvatar.loading = Loading.PENDING;
    });
    builder.addCase(updateReviewerAvatar.rejected, (state, { payload }) => {
      state.uploadAvatar.loading = Loading.ERROR;
    });
    builder.addCase(updateReviewerAvatar.fulfilled, (state, { payload }) => {
      state.uploadAvatar.loading = Loading.SUCCESS;
    });

    builder.addCase(createReview.pending, (state) => {
      state.createReview.loading = Loading.PENDING;
    });
    builder.addCase(createReview.rejected, (state, { payload }) => {
      state.createReview.loading = Loading.ERROR;
      state.createReview.error = payload as string;
    });
    builder.addCase(createReview.fulfilled, (state) => {
      state.createReview.loading = Loading.SUCCESS;
    });

    builder.addCase(bulkDeleteReviews.pending, (state) => {
      state.bulkDeleteReviews.loading = Loading.PENDING;
    });
    builder.addCase(bulkDeleteReviews.rejected, (state) => {
      state.bulkDeleteReviews.loading = Loading.ERROR;
    });

    builder.addCase(bulkDeleteReviews.fulfilled, (state) => {
      state.bulkDeleteReviews.loading = Loading.SUCCESS;
    });

    builder.addCase(bulkPublishReviews.pending, (state) => {
      state.bulkPublishReviews.loading = Loading.PENDING;
    });
    builder.addCase(bulkPublishReviews.rejected, (state) => {
      state.bulkPublishReviews.loading = Loading.ERROR;
    });

    builder.addCase(bulkPublishReviews.fulfilled, (state) => {
      state.bulkPublishReviews.loading = Loading.SUCCESS;
    });

    builder.addCase(getReviewsUsage.pending, (state) => {
      state.reviewUsage.loading = Loading.PENDING;
    });
    builder.addCase(getReviewsUsage.rejected, (state) => {
      state.reviewUsage.loading = Loading.ERROR;
    });
    builder.addCase(getReviewsUsage.fulfilled, (state, { payload }) => {
      state.reviewUsage.loading = Loading.SUCCESS;

      state.reviewUsage.textReviewsPublished =
        payload.totalTextReviewsPublished ?? 0;

      state.reviewUsage.videoReviewsPublished =
        payload.totalVideoReviewsPublished ?? 0;

      state.reviewUsage.published = payload.totalPublished ?? 0;

      state.reviewUsage.pending = payload.totalPending ?? 0;
      state.reviewUsage.withoutReplies = payload.withoutReplies ?? 0;

      state.reviewUsage.total =
        (payload.totalPending ?? 0) + (payload.totalPublished ?? 0);
    });
  },
});

export const { updateReviews, toggleAddReviewModal } = reviews.actions;

export const reviewsSelector = (state: RootState) => state.reviews;

export const reviewsUsageSelector = createSelector(
  reviewsSelector,
  ({ reviewUsage }) => reviewUsage
);

export default reviews.reducer;
